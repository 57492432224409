import React, { Fragment, useState, useCallback, Dispatch } from "react";
import moment from "moment";
import LocationService from "./LocationService";
import * as GoogleAnalytics from "../../lib/google-analytics";
import LocationServiceGuideIOS from "./LocationServiceGuideIOS";
import { logDataApi } from "../../lib/logDataApi";

interface InitialBookingProps {
  bookingItem: any;
  setModalErrorMessage: Dispatch<React.SetStateAction<string>>;
  setModalErrorSubMessage: Dispatch<React.SetStateAction<string>>;
  openModalError: () => void;
}

const InitialBooking = ({
  bookingItem,
  setModalErrorMessage,
  setModalErrorSubMessage,
  openModalError,
}: InitialBookingProps) => {
  const [isOpenModalLocationService, setIsOpenModalLocationService] =
    useState(false);
  const [
    isOpenModalLocationServiceGuideIOS,
    setIsOpenModalLocationServiceGuideIOS,
  ] = useState(false);

  const isIPhone = () => {
    const iPhone = "iPhone";
    if (navigator.userAgent.indexOf(iPhone) < 0) return false;
    else return true;
  };

  const openModalLocationService = useCallback(() => {
    setIsOpenModalLocationService(true);
  }, []);

  const closeModalLocationService = useCallback(() => {
    setIsOpenModalLocationService(false);
  }, []);

  const openModalLocationServiceGuideIOS = useCallback(() => {
    setIsOpenModalLocationServiceGuideIOS(true);
  }, []);

  const closeModalLocationServiceGuideIOS = useCallback(() => {
    setIsOpenModalLocationServiceGuideIOS(false);
    setIsOpenModalLocationService(true);
  }, []);

  const validateCheckIn = (checkinDate: string) => {
    GoogleAnalytics.customEvent({
      category: "button_click",
      action: "button_click_checkin_request",
    });

    if (bookingItem.rsvnNo)
      logDataApi({ rsvnNo: bookingItem.rsvnNo, progress: "2", ref: "Y" });

    const availableCheckinDate = `${checkinDate}T1500`;
    if (moment(availableCheckinDate) < moment()) {
      if (isIPhone()) openModalLocationServiceGuideIOS();
      else openModalLocationService();
    } else {
      setModalErrorMessage("체크인을 할 수 없습니다.");
      setModalErrorSubMessage("체크인은 입실 당일 3시 이후부터 가능합니다.");
      openModalError();
    }
  };

  const periodFormatter = (arrvDate: string, deptDate: string) => {
    return `${moment(arrvDate).format("YYYY.MM.DD")} ~ ${moment(
      deptDate
    ).format("YYYY.MM.DD")}`;
  };

  return (
    <Fragment>
      <div className="initial-booking-welcome-message-container">
        <span className="initial-booking-welcome-message">
          환영합니다. 고객님,
          <br />
          체크인을 진행하겠습니다.
        </span>
      </div>
      {bookingItem &&
      bookingItem.rsvnNo &&
      bookingItem.rsvnStatusCode === "RR" ? (
        <Fragment>
          <div className="initial-booking-card-container">
            <div className="hotel-img-container"></div>
            <span className="booking-period">
              {periodFormatter(bookingItem.arrvDate, bookingItem.deptDate)}
            </span>
            <span className="hotel-name">
              {/* {bookingItem.propertyName} 고정값으로 해달라고 요청 받음 */}
              탑스텐리조트 동강시스타
            </span>
            <div className="user-info-container">
              <img
                className="user-icon"
                src="../../assets/images/profile.png"
                alt=""
              />
              <span className="user-name">{bookingItem.guestName}</span>
              <span className="booking-number">{bookingItem.rsvnNo}</span>
            </div>
            <div
              className="check-in-button"
              onClick={() => validateCheckIn(bookingItem.arrvDate)}
            >
              <span className="check-in-button-text">체크인 / 체크아웃</span>
            </div>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className="initial-booking-card-container">
            {/* <div className='hotel-img-container'></div> */}
            <span className="no-booking">예약을 찾을 수 없습니다.</span>
          </div>
        </Fragment>
      )}
      <LocationService
        isOpen={isOpenModalLocationService}
        bookingItem={bookingItem}
        onClose={closeModalLocationService}
      />
      <LocationServiceGuideIOS
        isOpen={isOpenModalLocationServiceGuideIOS}
        onClose={closeModalLocationServiceGuideIOS}
      />
    </Fragment>
  );
};

export default InitialBooking;
